/*============= social nav two  ==============*/
.social-nav-element-two{
  a{
    color: rgba(255, 255, 255, 0.6);
    display: inline-block;
    padding:5px 10px;
    font-size: 25px;
    transition: all .4s ease;
    &:hover{
      color: $catering-primary-color;
    }
  }
}