/*about-social section block css*/
.about_social{
  padding: 100px 0;
  .lead_title{
    font-size: 44px;
    margin-top: 5px;
    margin-bottom: 30px;
    @media #{$laptop} {
      margin-top: 0;
      margin-bottom: 10px;
    }
    @media #{$tab} {
      margin-top: 50px;
    }
  }
  .lead-text{
    font-size: 19px;
    color: $secondaryTextColor;
    margin-bottom: 20px;
    @media #{$laptop} {
      margin-bottom: 0;
    }
  }
  .about_social_img{
    //box-shadow: 0 28px 57px 0 rgba(0, 0, 0, 0.35);
  }
}

