/*================= modular section related css  =====================*/
@import "preloder";
@import "header";
@import "nav";
@import "ham-burger-menu";
@import "ham-burger-menu-two";
@import "breadcrumbs";


@import "about-one";
@import "about-social";
@import "about-social-two";
@import "about-three";
@import "grid-text-one";
@import "send-mail";
@import "gallery-one";
@import "feature-one";
@import "testimonial-one";
@import "skill-one";
@import "get-started-one";
@import "section-with-image";
@import "call-to-action";
@import "call-to-action-two";
@import "feature-two";
@import "counter-up";
@import "counter-up-two";
@import "pricing";
@import "pricing-two";
@import "team";
@import "masonary-gallery";
@import "feature-three";
@import "instagram";
@import "video-gallery";



@import "footer-one";
@import "footer-two";