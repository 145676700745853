/*===============preloder css start==================*/
.js div#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #ffffff url('../img/preloader.gif') no-repeat;
  background-size: 150px auto;
  background-position: calc(50%) 50%;
}