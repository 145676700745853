/*============= skills one css==============*/
.skills{
  .skill-list{
    overflow: hidden;
    .progress {
      background-color: #eee;
      border-radius: 0;
      height: 2px;
      position: relative;
      margin: 60px 0 50px 0;
      box-shadow: none;
      overflow: visible;
      h6{
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 700;
        left: 0;
        top: -40px;
        text-transform: uppercase;
        position: absolute;
        z-index: 99;
      }
    }
    .progress-bar {
      background-color: #111;
      span {
        background-color: #111;
        position: relative;
        float: right;
        padding: 0 5px;
        font-size: 12px;
        margin-top: -23px;
        :after{
          position: absolute;
          content: "";
          border-width: 6px 4px 0 4px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 1) transparent transparent transparent;
          left: 50%;
          bottom: -6px;
          margin-left: -4px;
        }
      }
    }
  }
}