/*============= YOUTUBE page css start ===============*/
.youtuber{
  background: #ececec url("../img/brick-pattern.png") center top no-repeat;
  .nav-bg{
    border: none;
  }
  .bg-primary{
    background: #fff;
  }
  .bg-dark{
    background: #ffffff;
    border-bottom: 1px solid #bcbcbc;
  }
  .heading_burger_nav_two{
    .nav-head-two span{
      padding-left: 0;
      margin-left: -3px;
    }
    .burger-icon{
      padding: 5px 0 0 15px;
    }
  }
  .mix-btn{
    background: $youtuber-primary-color;
    color: #ffffff;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $youtuber-primary-color;
      border: 1px solid $youtuber-primary-color;
    }
  }
  .counter-up-two{
    background: url("../img/counter-up-two-bg.png") center center no-repeat;
    background-size: cover;
    color: #ffffff;
    .counter-up-text{
      color: #ffffff;
    }
  }
  .video-gallery{
    background: #ffffff;
    padding: 70px 60px;
  }
  .venobox-video{
    .venobox{
      background: $youtuber-primary-color;
      color: #ffffff;
      border: 1px solid $youtuber-primary-color;
      &:hover{
        background: #ffffff;
        color: $youtuber-primary-color;
        border: 1px solid #ffffff;
      }
    }
  }
  .footer-two .footer-top{
    background: url("../img/youtuber-footer.png") center center no-repeat;
    background-size: cover;
  }
  .footer-two .footer-mid{
    padding: 50px;
    background: #ffffff;
  }
  .footer-two .footer-bottom{
    background: transparent;
  }
  .footer-two {
    .footer-bottom {
      color: #505050;
      .footer-logo {
        color: #505050;
      }
      .f-soc-nav a i {
        color: #505050;
      }
    }
  }
}

.youtuber-color{
  color: $youtuber-primary-color;
}

.youtuber_header{
  background: transparent;
  padding-top: 101px;
  @media #{$desktopLgToMobile} {
    padding-top: 60px;
  }
}

.youtuber_banner{
  .venobox-video{
    .venobox{
      background: $youtuber-primary-color;
      border: transparent;
      color: #ffffff;
      &:hover{
        background: #ffffff;
        color: $youtuber-primary-color;
        border: 1px solid transparent;
      }
    }
  }
}