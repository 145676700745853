/*============= universal css ================ */

body{
  font: 100% $primaryFont;
  font-size: 14px;
  color: $primaryTextColor;
  font-weight: 400;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  position: relative;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $primaryFont;
  color: $primaryTextColor;
  font-weight: 700;
  line-height: 1.2;
}
a, a:hover, a:visited, a:focus{
  text-decoration: none;
  transition: all .4s ease;
  font-family: $primaryFont;
}
.img-responsive{
  margin: auto;
}
.section-title{
  font-size: $section-heading-font-size;
  margin-top: 0;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 0;
}
.section-sub-title{
  font-size: $section-sub-heading-font-size;
  color: $primaryTextColor;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 5px;
}
.divider-style-1{
  background: #e5e6ea;
  height: 1px;
}
.mix-btn {
  text-transform: uppercase;
  border: 1px solid rgba(255,255,255,0);
  border-radius: 25px;
  background: $primaryColor;
  color: #ffffff;
  font-size: 14px;
  padding: 9px 24px;
  display: inline-block;
  margin: 20px auto;
  font-weight: 700;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  transition: all .7s ease;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  &:hover, &:active, &:focus{
    color: $primaryColor;
    border: 1px solid $primaryColor;
    background: #ffffff;
  }
}
.transform-btn{
  text-transform: uppercase;
  border: 1px solid $secondaryColor;
  border-radius: 25px;
  background: $primaryColor;
  color: #ffffff;
  font-size: 14px;
  padding: 14px 39px;
  display: inline-block;
  margin: 20px auto;
  font-weight: 700;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  transition: color .4s ease;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  &:before{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    border-radius: 25px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  &:hover{
    color: $primaryColor;
    border: 1px solid $primaryColor;
    &:before{
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
  }
}
.div-height-fix-flex{
  -webkit-align-items: center;
  align-items: center;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  @media #{$tab} {
    display: block;
  }
}
blockquote{
  margin: 24px auto;
  background: #f8faff;
  color: #222222;
  padding: 30px;
  font-size: 16px;
  p{
    font-style: italic;
    color: #767676;
  }
}
.bounce-btn{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  margin: .4em 0;
  padding: 1em;
  cursor: pointer;
  background: #e1e1e1;
  text-decoration: none;
  line-height: 140%;
  color: #666;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098D1;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  &:hover{
    color: white;
    &:before{
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
  }
}
.vbox-close{
  top: 100px;
  @media #{$desktopLgToMobile} {
    top: 60px;
  }
}
.overlay {
  position:relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(41, 41, 41, 0.4);
    z-index: -1;
  }
}
.copy {
  a {
    color: #505050;
    &:hover {
      color:#717171;
    }
  }
}
