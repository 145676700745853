/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/

/* ==========================================
font-family: 'Open Sans', sans-serif;
=============================================*/


/*--------------------------------------------------------------
# Table of content
--------------------------------------------------------------*/
/* ==================================
1. basic css directory
==================================
  1.1 universal css
  1.2 BootStarp settings

==================================
2. Property directory css
==================================
  2.1 Typography css
    2.1.1 font color
    2.1.2 font family
    2.1.3 font weight
    2.1.4 line height
    2.1.5 font size
    2.1.6 text-transform
  2.2 margin css
    2.2.1 clear margin
    2.2.2 MARGIN TOP
    2.2.3 MARGIN BOTTOM
    2.2.4 MARGIN RIGHT
    2.2.5 MARGIN LEFT
    2.2.5 MARGIN LEFT
    2.2.6 margin bottom for small device
    2.2.7 margin top for small device
    2.2.8 margin bottom for small device landscape
    2.2.9 margin top for small device landscape
  2.3 padding css
    2.3.1 clear padding
    2.3.2 padding TOP
    2.3.3 padding BOTTOM
    2.3.4 padding RIGHT
    2.3.5 padding LEFT
    2.3.5 padding LEFT
    2.3.6 padding bottom for small device
    2.3.7 padding top for small device
    2.3.8 padding bottom for small device landscape
    2.3.9 padding top for small device landscape
  2.4 display property css
  2.5 visiblity property css
  2.6 color property css
  2.7 border property css

==================================
3. ELEMENTS related
==================================
  3.1 social nav css
  3.2 social nav two
  3.3 skills one css
  3.4 email subscribe element css
  3.5 email subscribe element two css
  3.6 Down arrow css
  3.7 venobox video element css

==================================
4. modular section related css
==================================
  4.1 preloder css
  4.2 header css
  4.3 nav css
  4.4 hamburger menu css
  4.5 ham burger menu two
  4.6 breadcrumb css
  4.7 aboutOne section block css
  4.8 about-social section block css
  4.9 about-social-two section block csS
  4.10 about section three css
  4.11 grid text block css
  4.12 send mail button section css
  4.13 Gallery style one css
  4.14 feature one section CSS
  4.15 testimonial one CSS
  4.16 SKILL-one section block css
  4.17 get started section css
  4.18 section with image css
  4.19 call to action section CSS
  4.20 call to action two css
  4.21 feature two css
  4.22 counter up css
  4.23 counter up css start
  4.24 pricing css
  4.25 pricing table two css
  4.26 team css
  4.27 masonry gallery css
  4.28 feature three css
  4.29 Instagram feed css
  4.30 video gallery css
  4.31 footer css
  4.32 footer two css

PAGE CSS
  1. TRAVEL PAGE CSS
  2. RESUME PAGE CSS
  3. CHARITY PAGE CSS
  4. SOFTWARE PAGE CSS
  5. STARTUP PAGE CSS
  6. PORTFOLIO PAGE CSS
  7. FASHION PAGE CSS
  8. CONSULTING PAGE CSS
  9. CATERING PAGE CSS
  10. YOUTUBE PAGE CSS
  10. PREVIEW PAGE CSS



========================================*/

/*--------------------------------------------------------------
# Variables
--------------------------------------------------------------*/
@import "variables";

/*--------------------------------------------------------------
# Basic css
--------------------------------------------------------------*/
@import "1-basic/basic-dir";

/*--------------------------------------------------------------
# Property css
--------------------------------------------------------------*/
@import "2-property/property-dir";

/*--------------------------------------------------------------
# Elements css  
--------------------------------------------------------------*/
@import "3-elements/elements-dir"; 

/*--------------------------------------------------------------
# Modular css
--------------------------------------------------------------*/
@import "4-modules/modules-dir";


/*============== page css start =================*/
@import "travel"; 
@import "resume"; 
@import "charity";
@import "software";
@import "startup";
@import "portfolio";
@import "fashion";
@import "consulting";
@import "catering"; 
@import "youtube";
@import "preview";

/* =========== page css start =====================*/



/*responsive css*/


@media (max-width: 767px) {
  body {
    font-size: 18px;
  }
}

