/*============Gallery style one css==============*/
.gallery_one{
  padding: 100px 0 50px;
}

/*========= gallery header css===========*/
.gallery-header{
  margin-bottom: 40px;
}
.gallery-title{
  h2{
    font-size: 33px;
    margin: 0;
  }
}
.sorting-menu{
  text-align: right;
  @media #{$mobile} {
    text-align: left;
    margin-top: 40px;
  }
  .category-text{
    display: inline-block;
    margin-right: 15px;
    font-weight: 700;
    color: #252525;
  }
  .dropdown{
    display: inline-block;
    .btn-default{
      color: $primaryColor;
      padding: 14px 20px;
      .caret{
        margin-left: 5px;
      }
    }
    .dropdown-menu{
      background-color: rgba(197, 197, 197, 0.98);
    }
  }
}


/*============ gallery body css start =============*/
.gallery-body{

}

.gallery-single{
  text-align: center;
  margin-bottom: 40px;
  .gallery-img{

  }
  figcaption{
    h4{
      font-size: 19px;
      margin-top: 17px;
      margin-bottom: 0;
      line-height: 1.4;
      span {
        display: block;
        color: $primaryTextColor;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.vbox-content{
  img{
    border: 20px solid #ffffff;
    border-bottom: 60px solid #ffffff;
    border-radius: 10px;
  }
}
