/*============ software page css start =============*/
.software{
  .bg-primary{
    background: $software-primary-color;
  }
  .nav-bg{
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    .navigation-right-btn li a{
      &:hover{
        background: #ffffff;
        color: $primaryTextColor;
      }
    }
  }
  .bg-dark{
    background: #16aea4;
  }
  .mix-btn{
    background: $software-primary-color;
    color: #ffffff;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $software-primary-color;
      border: 1px solid $software-primary-color;
    }
  }
  .footer-two .footer-top{
    background: url("../img/software-footer.png") center center no-repeat;
    background-size: cover;
  }
}
.software_header{
  background-image: -moz-linear-gradient( 230deg, #560f90 50%, #ce3895 50%, #ce3895 100%);
  background-image: -webkit-linear-gradient(230deg, #560f90 50%, #ce3895 50%, #ce3895 100%);
  background-image: -ms-linear-gradient( 230deg, #560f90 50%, #ce3895 50%, #ce3895 100%);
  background-image: linear-gradient(230deg, #560f90 50%, #ce3895 50%, #ce3895 100%);

}

.software_banner{
  text-align: center;
  margin-top: 0;
  padding: 30px 0 20px;
  .banner-text{
    text-align: right;
    padding: 20% 0 15%;
    img{
      max-height: 100px;
      margin-right: 0;
      @media #{$desktopLgToMobile} {
        max-height: 70px;
      }
    }
    p{
      font-size: 44px;
      margin: 20px 0;
      line-height: 1.2;
    }
  }
  .mix-btn{
    background: #ffffff;
    border: 1px solid #ffffff;
    margin: 0 0 10px 20px;
    color: $primaryTextColor;
    @media #{$mobile} {
      margin: 10px 0;
    }
    &:hover{
      background: transparent;
      border: 1px solid #ffffff;
      color: #ffffff;
    }
  }
}