/*============call to action section CSS===============*/
.call_to_action{
  background: url("../img/charity-cta.jpg") center center no-repeat;
  background-size: cover;
  text-align: center;
  padding: 55px 0;
  h3{
    color: #ffffff;
    font-size: 42px;
    font-weight: 500;
  }
}