/*============Resume landing page css start==============*/
.resume{
  .nav-bg .navigation-right-btn li a:hover{
    background: $resume-primary-color;
  }
  .nav-bg {
    border-bottom: 1px solid rgba(255, 255, 255, 0.37);
  }
  .bg-primary{
    background: #817364;
  }
  .sorting-menu .dropdown .btn-default{
    color: $resume-primary-color;
  }
  .mix-btn{
    background: $resume-primary-color;
    color: #ffffff;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $resume-primary-color;
      border: 1px solid $resume-primary-color;
    }
  }
  .about_social{
    .about_social_text{
      padding-top: 70px;
      @media #{$tab} {
        padding-top: 0;
      }
      .mix-btn{
        padding: 9px 50px;
      }
    }
    .social-nav{
      display: block;
      text-align: right;
      margin: 20px 0;
      @media #{$mobile} {
        margin-top: 0;
        text-align: left;
      }
    }
  }
  .skill_one{
    .skill_text{
      padding-top: 40px;
      @media #{$tab} {
        padding-top: 0;
      }
      .mix-btn{
        padding: 9px 50px;
      }
    }
    .social-nav{
      display: block;
      text-align: right;
      margin: 20px 0;
      @media #{$mobile} {
        margin-top: 0;
        text-align: left;
      }
    }
  }
  .get-started{
    background: $resume-primary-color;
    .mix-btn{
      border: 1px solid #ffffff;
      &:hover{
        color: $resume-primary-color;
        background: #ffffff;
      }
    }
  }
}

.resume_color{
  color: $resume-primary-color;
}

.resume_header{
  background: url(../img/resume_bg.png) center center no-repeat;
  background-size: cover;
}

.resume_banner{
  text-align: center;
  padding: 160px 0 140px;
  @media #{$mobile} {
    padding: 90px 0 40px;
  }
  h1{
    font-size: 65px;
    font-weight: 500;
    color: #ffffff;
    line-height: 58px;
    margin: 0;
    @media #{$mobile} {
      font-size: 31px;
    }
  }
  p{
    font-size: 25px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 30px;
    @media #{$mobile} {
      font-size: 21px;
    }
  }
  .mix-btn{
    background: transparent;
    border: 1px solid #ffffff;
    &:hover{
      background: $resume-primary-color;
      border: 1px solid transparent;
      color: #ffffff;
    }
  }
}

.travel-grid-text-one{
  padding-bottom: 50px;
}

