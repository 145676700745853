/*==================footer two css start================*/
.footer-two{
  .footer-top{
    background: url("../img/footer-two-bg.jpg") center center no-repeat;
    background-size: cover;
    padding: 80px 0 40px;
    .footer-two-title{
      font-size: 33px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 20px;
      margin: 0 0 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    .tel-col, .email-col, .address-col{
      text-align: center;
    }
    .tel, .email, .address{
      color: #ffffff;
      font-weight: 600;
    }
  }
  .footer-mid {
    padding: 50px 0 73px;
    .footer-two-form{
      .form-group{
        label{
          text-transform: uppercase;
          color: #505050;
          margin-bottom: 5px;
        }
      }
      .form-control{
        background: #eff0f4;
        //color: #ffffff;
        border: 1px solid rgba(170, 170, 170, 0.01);
        border-radius: 10px;
        padding-left: 20px;
        margin-bottom: 2px;
        &:hover{
          border: 1px solid rgba(170, 170, 170, 0.7);
        }
      }
      .form-control[type=text], .form-control[type=email]{
        height: 40px;
        border-radius: 20px;
      }
      .mix-btn{
        margin: 30px 0 0;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .footer-bottom{
    color: #afafaf;
    padding: 37px 0;
    background: #252525;
    .footer-name{
      @media #{$mobile} {
        text-align: center;
      }
    }
    .footer-logo{
      font-size: 21px;
      color: #afafaf;
      font-weight: 700;
    }
    .copy{
      text-align: right;
      margin-bottom: 0;
      @media #{$mobile} {
        text-align: center;
      }
      a {
        color:#afafaf;
        &:hover {
          color: #fff;
        }
      }
    }
    .f-soc-nav{
      display: block;
      text-align: right;
      @media #{$mobile} {
        margin-top: 30px;
        text-align: center;
        display: block;
        float: none;
      }
      a{
        margin-right: 15px;
        display: inline-block;
        &:last-child{
          margin-right: 0;
        }
        i{
          color: #afafaf;
          border-radius: 50%;
          line-height: 31px;
          text-align: center;
          transition: all .4s ease;
        }
        i:hover{
          color: #ffffff;
          background: transparent;
        }
      }
    }
  }
}