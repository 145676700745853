/*====================feature three css start here=======================*/
.feature-three{
  padding: 100px 0;
  .feature-three-img{
    padding: 10px 30px 0 0;
    margin-left: auto;
    margin-right: 0;
    @media all and (max-width: 1799px) {
      padding: 5% 30px 0 0;
    }
    @media all and (max-width: 1499px) {
      padding: 13% 30px 0 0;
    }
    @media #{$laptop} {
      padding: 35% 30px 0 0;
    }
    @media #{$tab} {
      padding: 0 0 50px;
      margin: auto;
    }
  }
  .feature-three-text{
    color: $secondaryTextColor;
    max-width: 570px;
    margin-top: 5px;
    @media #{$tab} {
      margin: auto;
    }
    .feature-three-header{
      margin-bottom: 70px;
      h2{
        font-size: 33px;
        margin: 0 0 20px;
      }
      p{
        font-size: 19px;
      }
    }
    .feature-three-feature{
      i{
        font-size: 44px;
        color: $software-primary-color;
      }
      h5{
        text-transform: uppercase;
        margin-bottom: 15px;
        margin-top: 15px;
        line-height: 1.1;
      }
      p{
        margin-bottom: 0;
      }
    }
  }
}