/*======== email subscribe element css start ==========*/
.subscribe-form{
  label{
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 600;
    @media #{$mobile} {
      font-size: 14px;
    }
  }
  .input-group{
    margin: 0 0 20px;
  }
  input {
    height: 40px;
    border-radius: 25px 0 0 25px;
    padding: 6px 25px;
  }
  button{
    height: 40px;
    border-radius: 0 25px 25px 0;
    padding: 6px 25px;
    background: #eac11f;
    text-transform: uppercase;
    font-weight: 700;
    border-color: #eac11f;
    &:hover{
      background: #c7a417;
      border-color: #c7a417;
    }
  }
  p{
    font-size: 12px;
    line-height: 1.5;
    font-weight: 700;
  }
}