/*============== Instagram feed css start =================*/
.instagram-feed {
  margin-bottom: -10px;
  text-align: center;
  position: relative;
  .btn-instagram{
    position: absolute;
    top: calc(50% - 31px);
    left: calc(50% - 120px);
    padding: 20px 45px;
    text-transform: uppercase;
    @media #{$mobile} {
      top: calc(50% - 12px);
      left: calc(50% - 75px);
      padding: 4px 12px;
      font-size: 11px;
    }
  }
}
.lightwidget-widget {
  width: 100%;
  border: 0;
  overflow: hidden;
}