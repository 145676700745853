/*aboutOne section block css*/
.about_one{
  padding: 100px 0 70px;
  .lead_title{
    font-size: 44px;
    margin-top: 25px;
    margin-bottom: 25px;
    @media #{$laptop} {
      margin-top: 0;
      margin-bottom: 10px;
    }
    @media #{$tab} {
      margin-top: 50px;
    }
  }
  .lead-text{
    font-size: 19px;
    color: $secondaryTextColor;
    margin-bottom: 20px;
    @media #{$laptop} {
      margin-bottom: 5px;
    }
  }
  .mix-btn {
    margin-top: 10px;
  }
}

