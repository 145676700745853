/*============= about section three css ==============*/
.about_three{
  .table-responsive{
    border: none;
    margin-bottom: 0;
  }
  .about_container{
    display: table;
    background: #ffffff;
    @media #{$tab} {
      display: block;
      padding-bottom: 25px;
    }
    .about_col{
      display: table-cell;
      vertical-align: middle;
      width: 50%;

      @media #{$laptop} {
        padding-top: 25px;
        padding-bottom: 30px;
      }
      @media #{$tab} {
        display: block;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0px;
        img{
          max-height: 450px;
          padding: 50px 0 40px;
        }
      }
      .about_col_in{

        padding: 20px 50px;
        @media #{$tab} {
          padding-top: 45px;
        }
        h2{
          font-size: 33px;
          font-weight: 300;
          margin: 0 0 20px;
        }
        p{
          font-size: 19px;
          color: #808080;
        }
        .mix-btn{
          font-size: 12px;
          padding: 11px 58px;
          margin-bottom: 0;
        }
      }
    }
  }
}