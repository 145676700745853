/*============travel landing page css start==============*/
.travel{
  .nav-bg {
    border-bottom: 1px solid rgba(245, 245, 245, 0.37);
  }
  .bg-dark{
    background: black;
  }
  .bg-primary {
    background: #0058bb;
  }
  .nav-bg .navigation-right-btn li a:hover{
    background: #1bb8d6;
  }
  .mix-btn{
    background: #0058bb;
  }
  .mix-btn:hover,
  .mix-btn:active, 
  .mix-btn:focus{
    color: #fff;
    background: #0f4684;
    border-color: transparent;
  }
  .send-mail .phone-number,
  .sorting-menu .dropdown .btn-default{
    color: #0058bb;
  }

}
.travel_header{
  background: url(../img/travel-bg.png) center center no-repeat;
  background-size: cover;

}
.travel_banner {
  text-align: center;
  padding: 160px 0 140px;
  h1 {
    color: #fff;
  }
  @media #{$mobile} {
    padding: 80px 0 40px;
  }
  img{
    @media #{$mobile} {
      max-height: 100px;
    }
  }
  p{
    font-size: 44px;
    font-weight: 300;
    margin-top: 12px;
    @media #{$mobile} {
      font-size: 26px;
    }
  }
  a{
    &:hover{
      border: 1px solid transparent;
    }
    @media #{$mobile} {
      font-size: 12px;
    }
  }
  .mix-btn {
    background: #0058bb;
    margin-top: 15px;
  }
}
.travel-grid-text-one{
  padding-bottom: 50px;
}

