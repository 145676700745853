/*=============testimonial one CSS start=============*/
.testimonial{
  padding: 100px 0 90px;
}
.testimonial-one-title{
  font-size: 33px;
  text-align: center;
  margin: 0 0 60px;
}
.testimonial-one{
  .owl-controls{
    margin-top: 40px;
  }
}
.testimonial-col{
  text-align: center;
  padding: 0 35px;
  img{
    max-width: 100px;
    border-radius: 50%;
  }
  h3{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 25px;
    small{
      display: block;
      margin-top: 15px;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  p{
    font-size: 14px;
  }
}