/*======================= BootStarp settings  =========================*/
button, .btn:focus, a:focus{
  outline: none;
}
.form-control:focus{
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
@media #{$desktop} {
  .container {
    width: 1200px;
  }
}

/*nav bar css fix*/
.navbar-fixed-bottom, .navbar-fixed-top{
  padding: 10px 30px;
  @media #{$desktopLgToMobile} {
    padding: 10px 30px;
  }
}