/*====================feature two css start here=======================*/
.feature-two{
  padding: 100px 0;
  .feature-two-img{
    padding: 15% 0 7%;
    @media #{$laptop} {
      padding: 35% 0 7%;
    }
    @media #{$tab} {
      padding: 0 0 50px;
    }
  }
  .feature-two-text{
    color: $secondaryTextColor;
    .feature-two-header{
      margin-bottom: 70px;
      h2{
        font-size: 33px;
        margin: 0 0 20px;
      }
      p{
        font-size: 19px;
      }
    }
    .feature-two-feature{
      i{
        font-size: 44px;
        color: $software-primary-color;
      }
      h5{
        text-transform: uppercase;
        margin-bottom: 15px;
        margin-top: 15px;
        line-height: 1.1;
      }
      p{
        margin-bottom: 0;
      }
    }
  }
}