/*============= pricing table two css start here ===============*/
.pricing-table-two{
  padding: 100px 0 50px;
}
.pricing-two-col{
  text-align: center;
  border: 1px solid rgba(255, 255, 255,.102);
  border-radius: 25px;
  padding: 75px 55px 55px;
  max-width: 370px;
  margin: 0 auto 50px;
}
.price-text-two{
  font-size: 44px;
  font-weight: 300;
}
.menu-title-two{
  font-size: 33px;
  color: #ffffff;
  margin: 25px 0 10px;
}
.menu-description{
  font-size: 14px;
  color: #8b8c93;
  margin-bottom: 35px;
}