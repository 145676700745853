/*-----------------------
padding
-----------------------*/

.less-padding {
  padding:0 1px;

  @media #{$mobile} {
    padding:0 15px;
  }

  @media #{$mobileLandScape} {
    padding:0 15px;
  }
}

.clear-padding {
  padding: 0;
}

.clear-padding-top {
  padding-top: 0;
}

.clear-padding-bottom {
  padding-bottom: 0;
}

.clear-padding-left {
  padding-left: 0;
}

.clear-padding-right {
  padding-right: 0;
}

/*-------------padding left---------------*/
.p-l-5 {
  padding-left: 5px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-l-55 {
  padding-left: 55px;
}
.p-l-60 {
  padding-left: 60px;
}
.p-l-65 {
  padding-left: 65px;
}


/*right padding*/
.p-r-5 {
  padding-right: 5px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-r-35 {
  padding-right: 35px;
}
.p-r-40 {
  padding-right: 40px;
}
.p-r-45 {
  padding-right: 45px;
}
.p-r-50 {
  padding-right: 50px;
}
.p-r-55 {
  padding-right: 55px;
}
.p-r-60 {
  padding-right: 60px;
}
.p-r-65 {
  padding-right: 65px;
}

/*bottom padding*/

.p-b-5 {
  padding-bottom: 5px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-b-45 {
  padding-bottom: 55px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-b-55 {
  padding-bottom: 55px;
}
.p-b-60 {
  padding-bottom: 60px;
}
.p-b-65 {
  padding-bottom: 65px;
}

/*top padding*/

.p-t-5 {
  padding-top:5px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-t-45 {
  padding-top: 55px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-t-55 {
  padding-top: 55px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-t-65 {
  padding-top: 65px;
}



/*Padding for small screen*/
@media #{$mobile} {
  .s-p-l-5 {
    padding-left: 5px;
  }
  .s-p-l-10 {
    padding-left: 10px;
  }
  .s-p-l-15 {
    padding-left: 15px;
  }
  .s-p-l-20 {
    padding-left: 20px;
  }
  .s-p-l-25 {
    padding-left: 25px;
  }
  .s-p-l-30 {
    padding-left: 30px;
  }
  .s-p-l-35 {
    padding-left: 35px;
  }
  .s-p-l-40 {
    padding-left: 40px;
  }
  .s-p-l-45 {
    padding-left: 45px;
  }
  .s-p-l-50 {
    padding-left: 50px;
  }
  .s-p-l-55 {
    padding-left: 55px;
  }
  .s-p-l-60 {
    padding-left: 60px;
  }
  .s-p-l-65 {
    padding-left: 65px;
  }

  .s-p-r-5 {
    padding-right: 5px;
  }
  .s-p-r-10 {
    padding-right: 10px;
  }
  .s-p-r-15 {
    padding-right: 15px;
  }
  .s-p-r-20 {
    padding-right: 20px;
  }
  .s-p-r-25 {
    padding-right: 25px;
  }
  .s-p-r-30 {
    padding-right: 30px;
  }
  .s-p-r-35 {
    padding-right: 35px;
  }
  .s-p-r-40 {
    padding-right: 40px;
  }
  .s-p-r-45 {
    padding-right: 45px;
  }
  .s-p-r-50 {
    padding-right: 50px;
  }
  .s-p-r-55 {
    padding-right: 55px;
  }
  .s-p-r-60 {
    padding-right: 60px;
  }
  .s-p-r-65 {
    padding-right: 65px;
  }


  .s-p-b-5 {
    padding-bottom: 5px;
  }
  .s-p-b-10 {
    padding-bottom: 10px;
  }
  .s-p-b-15 {
    padding-bottom: 15px;
  }
  .s-p-b-20 {
    padding-bottom: 20px;
  }
  .s-p-b-25 {
    padding-bottom: 25px;
  }
  .s-p-b-30 {
    padding-bottom: 30px;
  }
  .s-p-b-35 {
    padding-bottom: 35px;
  }
  .s-p-b-40 {
    padding-bottom: 40px;
  }
  .s-p-b-45 {
    padding-bottom: 55px;
  }
  .s-p-b-50 {
    padding-bottom: 50px;
  }
  .s-p-b-55 {
    padding-bottom: 55px;
  }
  .s-p-b-60 {
    padding-bottom: 60px;
  }
  .s-p-b-65 {
    padding-bottom: 65px;
  }


  .s-p-t-5 {
    padding-top: 5px;
  }
  .s-p-t-10 {
    padding-top: 10px;
  }
  .s-p-t-15 {
    padding-top: 15px;
  }
  .s-p-t-20 {
    padding-top: 20px;
  }
  .s-p-t-25 {
    padding-top: 25px;
  }
  .s-p-t-30 {
    padding-top: 30px;
  }
  .s-p-t-35 {
    padding-top: 35px;
  }
  .s-p-t-40 {
    padding-top: 40px;
  }
  .s-p-t-45 {
    padding-top: 55px;
  }
  .s-p-t-50 {
    padding-top: 50px;
  }
  .s-p-t-55 {
    padding-top: 55px;
  }
  .s-p-t-60 {
    padding-top: 60px;
  }
  .s-p-t-65 {
    padding-top: 65px;
  }
}


@media #{$mobileLandScape} {
  .s-p-l-5 {
    padding-left: 5px;
  }
  .s-p-l-10 {
    padding-left: 10px;
  }
  .s-p-l-15 {
    padding-left: 15px;
  }
  .s-p-l-20 {
    padding-left: 20px;
  }
  .s-p-l-25 {
    padding-left: 25px;
  }
  .s-p-l-30 {
    padding-left: 30px;
  }
  .s-p-l-35 {
    padding-left: 35px;
  }
  .s-p-l-40 {
    padding-left: 40px;
  }
  .s-p-l-45 {
    padding-left: 45px;
  }
  .s-p-l-50 {
    padding-left: 50px;
  }
  .s-p-l-55 {
    padding-left: 55px;
  }
  .s-p-l-60 {
    padding-left: 60px;
  }
  .s-p-l-65 {
    padding-left: 65px;
  }

  .s-p-r-5 {
    padding-right: 5px;
  }
  .s-p-r-10 {
    padding-right: 10px;
  }
  .s-p-r-15 {
    padding-right: 15px;
  }
  .s-p-r-20 {
    padding-right: 20px;
  }
  .s-p-r-25 {
    padding-right: 25px;
  }
  .s-p-r-30 {
    padding-right: 30px;
  }
  .s-p-r-35 {
    padding-right: 35px;
  }
  .s-p-r-40 {
    padding-right: 40px;
  }
  .s-p-r-45 {
    padding-right: 45px;
  }
  .s-p-r-50 {
    padding-right: 50px;
  }
  .s-p-r-55 {
    padding-right: 55px;
  }
  .s-p-r-60 {
    padding-right: 60px;
  }
  .s-p-r-65 {
    padding-right: 65px;
  }


  .s-p-b-5 {
    padding-bottom: 5px;
  }
  .s-p-b-10 {
    padding-bottom: 10px;
  }
  .s-p-b-15 {
    padding-bottom: 15px;
  }
  .s-p-b-20 {
    padding-bottom: 20px;
  }
  .s-p-b-25 {
    padding-bottom: 25px;
  }
  .s-p-b-30 {
    padding-bottom: 30px;
  }
  .s-p-b-35 {
    padding-bottom: 35px;
  }
  .s-p-b-40 {
    padding-bottom: 40px;
  }
  .s-p-b-45 {
    padding-bottom: 55px;
  }
  .s-p-b-50 {
    padding-bottom: 50px;
  }
  .s-p-b-55 {
    padding-bottom: 55px;
  }
  .s-p-b-60 {
    padding-bottom: 60px;
  }
  .s-p-b-65 {
    padding-bottom: 65px;
  }


  .s-p-t-5 {
    padding-top: 5px;
  }
  .s-p-t-10 {
    padding-top: 10px;
  }
  .s-p-t-15 {
    padding-top: 15px;
  }
  .s-p-t-20 {
    padding-top: 20px;
  }
  .s-p-t-25 {
    padding-top: 25px;
  }
  .s-p-t-30 {
    padding-top: 30px;
  }
  .s-p-t-35 {
    padding-top: 35px;
  }
  .s-p-t-40 {
    padding-top: 40px;
  }
  .s-p-t-45 {
    padding-top: 55px;
  }
  .s-p-t-50 {
    padding-top: 50px;
  }
  .s-p-t-55 {
    padding-top: 55px;
  }
  .s-p-t-60 {
    padding-top: 60px;
  }
  .s-p-t-65 {
    padding-top: 65px;
  }
}