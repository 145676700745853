/*============= preview page css start ==================*/
.preview_header{
  padding-top: 0;
  .nav-bg .navigation-right-btn li a{
    background: #ea3427;
    border: 1px solid #ea3427;
    &:hover{
      background: transparent;
      color: #ea3427;
    }
  }
  @media #{$mobile} {
    .nav-bg .navbar-toggle .icon-bar{
      background: #ea3427;
    }
  }
}
.preview_banner{
  text-align: center;
  color: #000000;
  padding: 80px 0 80px;
  background: url(../img/heder.png) center center no-repeat;
  background-size: cover;
  .preview_banner_img{
    max-height: 120px;
    margin-bottom: 15px;
    @media #{$mobile} {
      max-height: 80px;
    }
  }
  h1{
    font-size: 20px;
    font-weight: 300;
    margin: 0 0 5px;
    color: #fff;
    letter-spacing: 7px;
    @media #{$mobile} {
      font-size: 13px;
    }
  }
}
.block_header{
  background: url("../img/travel-bg.png") center no-repeat;
  background-size: cover;
  .nav-bg {
    border-bottom: 1px solid rgba(202, 193, 193, 0.62);
  }
  .bg-transparent{
    .navbar-brand{
      span{
        color: #ffffff;
      }
    }
  }
  .bg-dark{
    background: #ffffff;
    .navbar-brand{
      span{
        color: #000000;
      }
    }
  }
  .nav-bg .navigation-right-btn li a{
    background: #ea3427;
    border: 1px solid #ea3427;
    &:hover{
      background: #ffffff;
      color: #ea3427;
      border: 1px solid transparent;
    }
  }
  .nav-bg.bg-dark .navigation-right-btn li a{
    background: #ea3427;
    border: 1px solid #ea3427;
    &:hover{
      background: transparent;
      color: #ea3427;
      border: 1px solid #ea3427;
    }
  }
  .nav-head-two span a {
    color: #fff;
  }
  .block-nav {
    padding-top: 18px;
    font-size: 19px;
    color: #fff;
    font-weight: 600;
    a {
      color: #fff;
      padding-right: 15px; 
    }
  }
  @media #{$mobile} {
    .nav-bg .navbar-toggle .icon-bar{
      background: #ea3427;
    }
  }
}
.block_banner{
  text-align: center;
  color: #000000;
  padding: 90px 0 100px;
  h1{
    font-size: 65px;
    font-weight: 300;
    margin: 0 0 5px;
    color: #ffffff;
    @media #{$mobile} {
      font-size: 45px;
    }
  }
}
.preview{
  .bg-dark{
    background: #ffffff;
  }
  .nav-bg {
    border-bottom: 1px solid rgba(108, 110, 110, 0.11);
  }
  .mix-btn{
    background: #ea3427;
    border: 1px solid #ea3427;
    &:hover{
      background: transparent;
      color: #ea3427;
    }
  }
  .call_to_action{
    background: url(../img/startup-bg.png) top center no-repeat;
    background-size: cover;
    .mix-btn{
      &:hover{
        background: #ffffff;
        border: 1px solid transparent;
      }
    }
  }
}
.divider-section-black{
  background: #000;
  color: #ffffff;
  text-align: center;
  padding: 27px 0;
  p{
    margin: 0;
    line-height: 26px;
    font-weight: 600;
  }
}
.show-case{
  background: #252525;
  h3{
    text-align: center;
    margin: 60px 0 30px;
    color: #ffffff;
    font-weight: 600;
  }
  .show-item{
    display: block;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 55px;

    max-height: 547px;
    overflow: hidden;
    @media #{$laptop} {
      margin-bottom: 35px;
    }
    @media #{$tab} {
      margin-bottom: 15px;
    }
    @media #{$mobile} {
      margin-bottom: 35px;
    }
    img{
      border-radius: 10px;
      margin-bottom: 15px;
      transition: transform .4s ease;
      &:hover{
        transform: scale(1.03);
      }
    }
    p{
      margin-bottom: 0;
      color: #ffffff;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
.horizontal-line{
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}