/*================feature one section CSS start  ===================*/
.feature-one{
  background: url("../img/feature_one_bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 100px 0;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: -1;
  }
}
.feature-col{
  text-align: center;
  color: #ffffff;
  padding: 60px 40px 40px;
  margin-left: -15px;
  margin-right: -15px;
  i{
    font-size: 50px;
  }
  h4{
    font-size: 14px;
    color: inherit;
    line-height: 58px;
    margin: 0px;
    text-transform: uppercase;
  }
  p{
    font-size: 14px;
    margin: 0;
  }
  @media #{$mobile} {
    margin-left: 0;
    margin-right: 0;
  }
}
.feature-col-one{
  border-bottom: 1px solid #655f5c;
  border-right: 1px solid #655f5c;
  @media #{$mobile} {
    border-right: none;
  }
}
.feature-col-two{
  border-bottom: 1px solid #655f5c;
  border-right: 1px solid #655f5c;
  @media #{$tab} {
    border-right: none;
  }
}
.feature-col-three{
  border-bottom: 1px solid #655f5c;
  @media #{$tab} {
    border-right: 1px solid #655f5c;
  }
  @media #{$mobile} {
    border-right: none;
  }
}
.feature-col-four{
  border-right: 1px solid #655f5c;
  @media #{$tab} {
    border-right: none;
    border-bottom: 1px solid #655f5c;
  }
}
.feature-col-five{
  border-right: 1px solid #655f5c;
  @media #{$mobile} {
    border-right: none;
    border-bottom: 1px solid #655f5c;
  }
}