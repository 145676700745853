/*==================footer css start================*/
.footer-one{
  .footer-top{
    background: #131313;
    padding: 80px 0 75px;
    .footer-one-title{
      font-size: 33px;
      text-align: center;
      color: #ffffff;
      margin: 0 0 45px;
    }
    .footer-top-col{
      h3{
        font-size: 19px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        line-height: 28px;
        text-transform: uppercase;
        color: #efefef;
      }
      p{
        color: #ffffff;
        line-height: 28px;
        margin-bottom: 20px;
        a{
          color: #ffffff;
        }
      }
    }
    .footer-one-form{
      .form-group{
        margin-bottom: 30px;
        &:nth-child(3){
          margin-bottom: 10px;
        }
      }
      .footer-form-wrapper{
        @media #{$mobile} {
          margin-top: 30px;
        }
      }
      .form-control{
        background: #424242;
        color: #ffffff;
        border: 1px solid rgba(170, 170, 170, 0.01);
      }
      .form-control[type=text], .form-control[type=email]{
        height: 40px;
      }
      .select2-container .select2-selection--single{
        height: 40px;
        background: #424242;
        border: 1px solid rgba(170, 170, 170, 0.01);
      }
      .select2-container--default .select2-selection--single .select2-selection__rendered{
        line-height: 40px;
        padding-left: 15px;
        color: #909090;
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow{
        height: 40px;
      }
    }
  }
  .footer-bottom{
    color: #505050;
    padding: 37px 0;
    .footer-name{
      @media #{$mobile} {
        text-align: center;
      }
    }
    .footer-logo{
      font-size: 21px;
      color: #505050;
      font-weight: 700;
    }
    .copy{
      text-align: right;
      margin-bottom: 0;
      @media #{$mobile} {
        text-align: center;
      }
    }
    .f-soc-nav{
      display: block;
      text-align: right;
      @media #{$mobile} {
        margin-top: 30px;
        text-align: center;
        display: block;
        float: none;
      }
      a{
        margin-right: 15px;
        display: inline-block;
        &:last-child{
          margin-right: 0;
        }
        i{
          color: #505050;
          border-radius: 50%;
          border: 2px solid #ffffff;
          background: #ffffff;
          line-height: 31px;
          text-align: center;
          transition: all .4s ease;
        }
        i:hover{
          //color: #ffffff;
          //background: transparent;
        }
      }
    }
  }
}