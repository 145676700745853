/*============ call to action two css ===============*/
.call_to_action_two{
  padding: 100px 0;
  .call_to_action_two_inner{
    background: url("../img/bench-accounting.png") center center no-repeat;
    background-size: cover;
    text-align: center;
    padding: 65px 0;
    h3{
      font-size: 42px;
    }
  }
}