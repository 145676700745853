/*============ startup page css start here ===========*/
.startup{
  .bg-transparent{
    background: #ffffff;
  }
  .bg-dark{
    background: #ffffff;
  }
  .bg-primary{
    background: #ffffff;
  }
  .nav-bg{
    border: none;
  }
  .mix-btn{
    background: $startup-primary-color;
    color: #ffffff;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $startup-primary-color;
      border: 1px solid $startup-primary-color;
    }
  }
  .call_to_action{
    background: url(../img/startup-bg.png) top center no-repeat;
    background-size: cover;
    padding: 100px 0;
    .mix-btn{
      &:hover{
        background: #ffffff;
        color: $startup-primary-color;
      }
    }
  }
}
.startup_header{
   background: url(../img/startup-banner.png) left center no-repeat;
   background-size: cover;
 }
.startup_banner{
  text-align: center;
  padding: 160px 0 140px;
  h1 {
    color: #fff;
  }
  @media #{$desktopLgToMobile} {
    padding: 80px 0 80px;
  }
  img{
    @media #{$desktopLgToMobile} {
      //max-height: 100px;
    }
  }
  p{
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .mix-btn{
    padding: 9px 37px;
    background: #ffffff;
    color: $primaryTextColor;
    &:hover{
      background: transparent;
      border: 1px solid #ffffff;
      color: #ffffff;
    }
  }
}