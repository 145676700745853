/* ================ nav scss =====================*/
.nav-bg{
  border-bottom: 1px solid #6c6e6e;
  .navbar-toggle{
    @media #{$mobile} {
      margin-top: 0px;
      margin-right: 0;
      padding: 7px 10px 0px;
      .icon-bar{
        background: #ffffff;
      }
    }
  }
  .navbar-brand{
    height: auto;
    padding: 0;
    line-height: 1;
    @media #{$desktopLgToMobile} {
      padding: 0;
    }
  }
  .navbar-nav{
    padding: 0;
    margin-right: 0;
    @media #{$desktopLgToMobile} {
      padding: 0;
    }
    li{
      a{
        font-family: $primaryFont;
        font-size: 13px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 700;
      }
      a:focus{
        color: #ffffff;
      }
      a:hover{
        background: transparent;
        color: #ffffff;
      }
    }
    li.active{
      border-bottom: 1px solid #fff;
      transition: border-bottom .2s ease;
    }
  }
  .navigation-center{
    padding-left: calc(50% - 300px);
  }
  .navigation-right-btn{
    margin-top: 4px;
    li{
      a{
        background: transparent;
        padding: 10px 32px;
        border-radius: 25px;
        color: #fff;
        border: 1px solid #fff;
        @media #{$mobile} {
          display: inline-block;
        }
        &:hover{
          background: $primaryColor;
          border: 1px solid transparent;
        }
      }
    }
  }
}


.nav>li>a:focus,
.nav>li>a:hover {
  text-decoration: none;
  background-color: transparent;
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color: transparent;
  border-color: #337ab7;
}
.dropdown-menu{
  background-color: #003;
  -webkit-box-shadow: 0 2px 9px 0 #444;
  box-shadow: 0 2px 9px 0 #444;
}
@media (min-width: 768px){
  .dropdown{
    a{
      .caret{
        margin-left: 7px;
      }
    }
  }
  .navbar-right .dropdown-menu {
    left: auto;
    right: -5px;
    border: 1px solid rgba(119, 119, 119, 0.64);
    padding: 0 15px;
    text-align: right;
    display: block;
    z-index: -100;
    opacity: 0;
    transition: all .4s ease;
    top: 50%;
    li{
      a{
        padding: 17px 20px 0;
      }
    }
    li:last-child{
      a{
        padding: 12px 20px 17px;
      }
    }
  }
  .open{
    .dropdown-menu{
      z-index: 1000;
      transition: all .4s ease;
      opacity: 1;
      top: 100%;
    }
  }
}