/*about-social-two section block css*/
.about_social-two{
  padding: 100px 0;

  .about_social_two_text{
    max-width: 570px;
    padding-left: 50px;
    @media #{$tab} {
      padding-left: 0;
      max-width: 100%;
    }
  }
  .lead_title{
    font-size: 44px;
    margin-top: 5px;
    margin-bottom: 30px;
    @media #{$laptop} {
      margin-top: 0;
      margin-bottom: 10px;
    }
    @media #{$tab} {
      margin-top: 50px;
    }
  }
  .lead-text{
    font-size: 18px;
    color: $secondaryTextColor;
    margin-bottom: 20px;
    @media #{$laptop} {
      margin-bottom: 0;
    }
  }
  .about_social_img {
      margin-right: 0;
      @media #{$tab} {
        margin-right: auto;
      }
  }
  @media only screen and(min-width: 1200px) {
    .lead_title {
      margin-top: 45px;
    }
  }
}

