/*============= CONSULTING page css start ===============*/
.consulting{
  .nav-bg{
    border: none;
  }
  .heading_burger_nav_two{
    //background: transparent;
    .nav-head-two span a{
      color: #ffffff;
    }
  }
  .bg-primary{
    background: $consulting-primary-color;
  }
  .subscribe-form{
    margin-bottom: 30px;
    p{
      a{
        color: #ffffff;
        &:hover{
          color: #ffffff;
        }
      }
    }
  }
  .mix-btn{
    background: $consulting-primary-color;
    color: #ffffff;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $consulting-primary-color;
      border: 1px solid $consulting-primary-color;
    }
  }
  .feature-col{
    i, h4{
      color: #ebc022;
    }
  }
  .feature-three .feature-three-text .feature-three-feature i{
    color: $consulting-primary-color;
  }
  .counter-up{
    background: url("../img/consulting-counter.jpg") center center no-repeat;
    background-size: cover;
    color: #ffffff;
    .counter-up-text{
      color: #ffffff;
    }
  }
  .feature-one{
    background: #252525;
  }
  .footer-two .footer-top{
    background: url("../img/software-footer.jpg") center center no-repeat;
    background-size: cover;
  }
}
.consulting-color{
  color: $consulting-primary-color;
}
.consulting_header{
  background: url(../img/consulting-banner.jpg) center center no-repeat;
  background-size: cover;
  padding-top: 0;
}
.consulting_banner{
  text-align: center;
  margin-top: 0;
  padding: 180px 0 130px;
  @media #{$mobile} {
    padding: 100px 0 40px;
  }
  .consulting_banner_inner{
    max-width: 600px;
    margin: auto;
  }
  h3{
    font-size: 44px;
    color: #ffffff;
    font-weight: 300;
    margin: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
    @media #{$mobile} {
      font-size: 21px;
    }
  }
  img{
    margin: 10px auto 30px;
    max-height: 100px;
    @media #{$mobile} {
      max-height: 60px;
      margin: 10px auto 15px;
    }
  }
  .mix-btn{
    margin: 40px 0 20px;
    color: #ffffff;
    @media #{$mobile} {
      margin: 10px 0;
    }
    &:hover{
      background: transparent;
      color: #ffffff;
    }
  }
}