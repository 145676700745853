/*-----------------
Start:margin
-----------------*/

/*clear margin*/

.clear-margin {
  margin: 0;
}

.clear-margin-top {
  margin-top: 0;
}

.clear-margin-bottom {
  margin-bottom: 0;
}

.clear-margin-left {
  margin-left: 0;
}

.clear-margin-right {
  margin-right: 0;
}


/*MARGIN TOP*/
.m-t-5 {
  margin-top:5px;
}
.m-t-10 {
  margin-top:10px;
}
.m-t-15 {
  margin-top:15px;
}
.m-t-20 {
  margin-top:20px;
}


.m-t-25 {
  margin-top:25px;

}


.m-t-30 {
  margin-top:30px;


}
.m-t-35 {
  margin-top:35px;
}
.m-t-40 {
  margin-top:40px;
}


.m-t-45 {
  margin-top:45px;
}


.m-t-50 {
  margin-top:50px;

}

.m-t-55 {
  margin-top:55px;
}


.m-t-60 {
  margin-top:60px;
}

.m-t-65 {
  margin-top:65px;
}

.m-t-70 {
  margin-top:70px;
}

.m-t-75 {
  margin-top:75px;
}

.m-t-80 {
  margin-top:80px;
}
.m-t-85 {
  margin-top:85px;
}
.m-t-90 {
  margin-top:90px;
}
.m-t-95 {
  margin-top:80px;
}
.m-t-100 {
  margin-top:80px;
}



/*MARGIN BOTTOM*/

.m-b-5 {
  margin-bottom:5px;
}
.m-b-10 {
  margin-bottom:10px;
}
.m-b-15 {
  margin-bottom:15px;
}
.m-b-20 {
  margin-bottom:20px;
}
.m-b-25 {
  margin-bottom:25px;
}
.m-b-30 {
  margin-bottom:30px;
}
.m-b-35 {
  margin-bottom:35px;
}
.m-b-40 {
  margin-bottom:40px;
}
.m-b-45 {
  margin-bottom:45px;
}
.m-b-50 {
  margin-bottom:50px;
}
.m-b-55 {
  margin-bottom:55px;
}
.m-b-60 {
  margin-bottom:60px;
}
.m-b-65 {
  margin-bottom:65px;
}
.m-b-70 {
  margin-bottom:70px;
}
.m-b-75 {
  margin-bottom:75px;
}
.m-b-80 {
  margin-bottom:80px;
}



/*MARGIN RIGHT*/
.m-r-0 {
  margin-right: 0;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-r-35 {
  margin-right: 35px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-r-45 {
  margin-right: 45px;
}
.m-r-50 {
  margin-right: 50px;
}

/*MARGIN LEFT*/
.m-l-0 {
  margin-left: 0;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-l-50 {
  margin-left: 50px;
}





@media #{$mobile} {
  .s-m-l-0 {
    margin-left: 0;
  }

  .s-m-r-0 {
    margin-right: 0;
  }




  /*margin bottom for small device*/

  .s-m-b-5 {
    margin-bottom:5px;
  }

  .s-m-b-10 {
    margin-bottom:10px;
  }
  .s-m-b-15 {
    margin-bottom:15px;
  }
  .s-m-b-20 {
    margin-bottom:20px;
  }
  .s-m-b-25 {
    margin-bottom:25px;
  }
  .s-m-b-30 {
    margin-bottom:30px;
  }
  .s-m-b-35 {
    margin-bottom:35px;
  }
  .s-m-b-40 {
    margin-bottom:40px;
  }
  .s-m-b-45 {
    margin-bottom:45px;
  }
  .s-m-b-50 {
    margin-bottom:50px;
  }
  .s-m-b-55 {
    margin-bottom:55px;
  }
  .s-m-b-60 {
    margin-bottom:60px;
  }




  /*margin top for small device*/
  .s-m-t-5 {
    margin-top:5px;
  }
  .s-m-t-10 {
    margin-top:10px;
  }
  .s-m-t-15 {
    margin-top:15px;
  }
  .s-m-t-20 {
    margin-top:20px;
  }
  .s-m-t-25 {
    margin-top:25px;
  }
  .s-m-t-30 {
    margin-top:30px;
  }
  .s-m-t-35 {
    margin-top:35px;
  }
  .s-m-t-40 {
    margin-top:40px;
  }
  .s-m-t-45 {
    margin-top:45px;
  }
  .s-m-t-50 {
    margin-top:50px;
  }
  .s-m-t-55 {
    margin-top:55px;
  }
  .s-m-t-60 {
    margin-top:60px;
  }
  .s-m-t-65 {
    margin-top:65px;
  }
  .s-m-t-70 {
    margin-top:70px;
  }
  .s-m-t-75 {
    margin-top:75px;
  }
  .s-m-t-80 {
    margin-top:80px;
  }

}







@media #{$mobileLandScape} {
  .s-m-l-0 {
    margin-left: 0;
  }

  .s-m-r-0 {
    margin-right: 0;
  }

  /*margin bottom for small device landscape*/
  .s-m-b-5 {
    margin-bottom:5px;
  }

  .s-m-b-10 {
    margin-bottom:10px;
  }
  .s-m-b-15 {
    margin-bottom:15px;
  }
  .s-m-b-20 {
    margin-bottom:20px;
  }
  .s-m-b-25 {
    margin-bottom:25px;
  }
  .s-m-b-30 {
    margin-bottom:30px;
  }
  .s-m-b-35 {
    margin-bottom:35px;
  }
  .s-m-b-40 {
    margin-bottom:40px;
  }
  .s-m-b-45 {
    margin-bottom:45px;
  }
  .s-m-b-50 {
    margin-bottom:50px;
  }
  .s-m-b-55 {
    margin-bottom:55px;
  }
  .s-m-b-60 {
    margin-bottom:60px;
  }

  /*margin top for small device landscape*/
  .s-m-t-5 {
    margin-top:5px;
  }
  .s-m-t-10 {
    margin-top:10px;
  }
  .s-m-t-15 {
    margin-top:15px;
  }
  .s-m-t-20 {
    margin-top:20px;
  }
  .s-m-t-25 {
    margin-top:25px;
  }
  .s-m-t-30 {
    margin-top:30px;
  }
  .s-m-t-35 {
    margin-top:35px;
  }
  .s-m-t-40 {
    margin-top:40px;
  }
  .s-m-t-45 {
    margin-top:45px;
  }
  .s-m-t-50 {
    margin-top:50px;
  }
  .s-m-t-55 {
    margin-top:55px;
  }
  .s-m-t-60 {
    margin-top:60px;
  }
  .s-m-t-65 {
    margin-top:65px;
  }
  .s-m-t-70 {
    margin-top:70px;
  }
  .s-m-t-75 {
    margin-top:75px;
  }
  .s-m-t-80 {
    margin-top:80px;
  }

}