/*===========Typography css==============*/

/*-----------------font color---------------------*/
._white{
  color: #ffffff;
}
._black{
  color: #000000;
}
._primary{
  color: $primaryColor;
}
._secondary{
  color: $secondaryColor;
}

/*-----------------font family---------------------*/
.roboto {
  font-family: 'Roboto', sans-serif;
}

.open-sans {
  font-family: 'Open Sans', sans-serif;;
}
.lato {
  font-family: 'Lato', sans-serif;
}

.lora {
  font-family: 'Lora', sans-serif;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.raleway {
  font-family: 'Raleway', sans-serif;
}

.libre {
  font-family: 'Libre Baskerville', serif;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}
/*-----------------font weight---------------------*/
.thin {
  font-weight:100;
}
.light {
  font-weight:300;
}
.regular {
  font-weight:400;
}
.medium {
  font-weight:500;
}
.semi-bold {
  font-weight:600;
}
.bold {
  font-weight:700;
}
.extra-bold {
  font-weight:800;
}
.Black {
  font-weight:900;
}
._100{
  font-weight: 100;
}
._300{
  font-weight: 300;
}
._400{
  font-weight: 400;
}
._500{
  font-weight: 500;
}
._600{
  font-weight: 600;
}
._700{
  font-weight: 700;
}
._800{
  font-weight: 800;
}
._900{
  font-weight: 900;
}

/*-----------------line height---------------------*/
._line-1{
  line-height: 1.1;
}
._line-2{
  line-height: 1.2;
}
._line-3{
  line-height: 1.3;
}
._line-4{
  line-height: 1.4;
}
._line-5{
  line-height: 1.5;
}
._line-6{
  line-height: 1.6;
}
._line-7{
  line-height: 1.7;
}

/*------------- font size ---------------*/
._12px{
  font-size:12px;
}
._13px{
  font-size:13px;
}
._14px{
  font-size:14px;
}
._15px{
  font-size:15px;
}
._16px{
  font-size:16px;
}
._17px{
  font-size:17px;
}
._18px{
  font-size:18px;
}
._19px{
  font-size:19px;
}
._20px {
  font-size:20px;
}
._21px {
  font-size:21px;
}
._22px {
  font-size:22px;
}
._23px {
  font-size:23px;
}
._24px {
  font-size:24px;
}
._25px {
  font-size:25px;
}
._26px {
  font-size:26px;
}
._27px {
  font-size:27px;
}
._28px {
  font-size:28px;
}
._29px {
  font-size:29px;
}
._30px {
  font-size:30px;
}
._31px {
  font-size:31px;
}
._32px {
  font-size:32px;
}
._33px {
  font-size:33px;
}
._34px {
  font-size:34px;
}
._35px {
  font-size:35px;
}
._36px {
  font-size:36px;
}
._37px {
  font-size:37px;
}
._38px {
  font-size:38px;
}
._39px {
  font-size:39px;
}
._40px {
  font-size:40px;
}

._41px {
  font-size:41px;
}
._42px {
  font-size:42px;
}
._43px {
  font-size:43px;
}
._44px {
  font-size:44px;
}
._45px {
  font-size:45px;
}
._46px {
  font-size:46px;
}
._47px {
  font-size:47px;
}
._48px {
  font-size:48px;
}
._49px {
  font-size:49px;
}
._50px {
  font-size:50px;
}
._51px {
  font-size:51px;
}
._52px {
  font-size:52px;
}
._53px {
  font-size:53px;
}
._54px {
  font-size:54px;
}
._55px {
  font-size:55px;
}
._56px {
  font-size:56px;
}
._57px {
  font-size:57px;
}
._58px {
  font-size:58px;
}
._59px {
  font-size:59px;
}
._60px {
  font-size:60px;
}
._61px {
  font-size:61px;
}
._62px {
  font-size:62px;
}
._63px {
  font-size:63px;
}
._64px {
  font-size:64px;
}
._65px {
  font-size:65px;
}
._66px {
  font-size:66px;
}
._67px {
  font-size:67px;
}
._68px {
  font-size:68px;
}
._69px {
  font-size:69px;
}
._70px {
  font-size:70px;
}
._71px {
  font-size:71px;
}
._72px {
  font-size:72px;
}
._73px {
  font-size:73px;
}
._74px {
  font-size:74px;
}
._75px {
  font-size:75px;
}
._76px {
  font-size:76px;
}
._77px {
  font-size:77px;
}
._78px {
  font-size:78px;
}
._79px {
  font-size:79px;
}
._80px {
  font-size:80px;
}

@media #{$mobile}{
  ._19px,._20px,._21px,._22px,._23px {
    font-size:18px;
  }
  ._24px,._25px,._26px,._27px,._28px,._29px,._30px {
    font-size:20px;
  }

  ._31px,._32px,._33px,._34px,._35px,._36px,._37px,._38px,._39px,._40px {
    font-size:22px;
  }
  ._41px,._42px,._43px,._44px,._45px,._46px,._47px,._48px,._49px,._50px {
    font-size:28px;
  }

  ._51px,._52px,._53px,._54px,._55px,._56px,._57px,._58px,._59px,._60px {
    font-size:28px;
  }

  ._61px,._62px,._63px,._64px,._65px,._66px,._67px,._68px,._69px,._70px,._71px,._72px,._73px,._74px,._75px,._76px,._77px,._78px,._79px,._80px,._81px,._82px,._83px,._84px,._85px,._86px,._87px,._88px,._89px,._90px,._91px,._92px,._93px,._94px,._95px,._96px,._97px,._98px,._99px,._100px,._101px,._102px,._103px,._104px,._105px,._106px,._107px,._108px,._109px,._110px,._111px,._112px,._113px,._114px,._115px,._116px,._117px,._118px,._119px,._120px {
    font-size:35px;
  }
}


@media #{$mobileLandScape}{
  ._21px,._22px,._23px {
    font-size:20px;
  }
  ._24px,._25px,._26px,._27px,._28px,._29px,._30px {
    font-size:24px;
  }

  ._31px,._32px,._33px,._34px,._35px,._36px,._37px,._38px,._39px,._40px {
    font-size:28px;
  }
  ._41px,._42px,._43px,._44px,._45px,._46px,._47px,._48px,._49px,._50px {
    font-size:32px;
  }

  ._51px,._52px,._53px,._54px,._55px,._56px,._57px,._58px,._59px,._60px {
    font-size:36px;
  }

  ._61px,._62px,._63px,._64px,._65px,._66px,._67px,._68px,._69px,._70px,._71px,._72px,._73px,._74px,._75px,._76px,._77px,._78px,._79px,._80px,._81px,._82px,._83px,._84px,._85px,._86px,._87px,._88px,._89px,._90px,._91px,._92px,._93px,._94px,._95px,._96px,._97px,._98px,._99px,._100px,._101px,._102px,._103px,._104px,._105px,._106px,._107px,._108px,._109px,._110px,._111px,._112px,._113px,._114px,._115px,._116px,._117px,._118px,._119px,._120px {
    font-size:40px;
  }
}


/*================== text-transform =====================*/
.uppercase{
  text-transform: uppercase;
}