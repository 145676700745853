/*============ ham burger menu two ==============*/
.heading_burger_nav_two{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1500;
  min-height: 75px;
  //background: #ffffff;
}
.burger_nav_two{
  position: relative;
  left: 0;
  right: 0;
  top: 10px;
}
.nav-head-two{
  width:100%;
  //background:#ffffff;
  height: 60px;
  line-height: 60px;
  //border-bottom:1px solid #dddddd;
  display:inline-block;
  font-size:12px;
  @media #{$desktopLgToMobile} {
    height: 60px;
    line-height: 60px;
  }
  span{
    padding-left:20px;
    font-size:50px;
    font-weight: 700;
    line-height: 55px;
    @media #{$desktopLgToMobile} {
      line-height: 55px;
    }
    a{
      color: $primaryTextColor;
    }
  }
}
.burger-icon{
  background:none;
  position:absolute;
  top:0;
  right:0;
  bottom: 0;
  line-height:45px;
  padding:5px 15px 0 15px;
  color:#999;
  border:0;
  font-size:1.4em;
  font-weight:bold;
  cursor:pointer;
  outline:none;
  //z-index:10000000000000;
}
.menu-cross-btn{
  position: relative;
  left: 55px;
  top: 15px;
  color: black;
  cursor: pointer;
  font-size: 30px;

  &:hover{
    color: rgb(255, 0, 0);
  }
}
#swipeNav{
  overflow-y: scroll;
  background: linear-gradient(to bottom, #ffffff 0%,#ffffff 100%,#7db9e8 100%);
  border-left: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: none;
  ul{
    margin-top: 30px;
    li{
      border-bottom: none;
    }
  }
  a{
    color: #000;
    padding: 15px 0 0 20px;
    transition: all .3s ease;
    &:hover{
      background: rgb(255, 255, 255);
      font-weight: 700;
    }
  }
}