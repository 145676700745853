/*================== video gallery css start ================*/
.video-gallery{
  padding: 100px 0;
  h2{
    font-size: 44px;
    text-align: center;
    margin: 0 0 40px;
  }
  .venobox-video{
    margin-bottom: 30px;
  }
}