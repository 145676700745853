/*=============== venobox video element css start ===============*/
.venobox-video{
  position: relative;
  .venobox{
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #f5f5f5;
    background: rgba(51, 51, 51, 0.7);
    color: #ffffff;
    font-size: 19px;
    line-height: 78px;
    text-align: center;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 35px);
    &:hover{
      background: rgba(218, 208, 208, 0.63);
      color: #ffffff;
      border: 2px solid #f5f5f5;
    }
    i{
      position: absolute;
      top: calc(50% - 9.5px);
      left: calc(50% - 5.5px);
    }
    @media #{$mobile} {
      width: 60px;
      height: 60px;
      line-height: 58px;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      font-size: 16px;
      i{
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 5px);
      }
    }
  }
  img{
    width: 100%;
  }
  figcaption{
    position: absolute;
    bottom: 4%;
    left: 4%;
    p{
      margin-bottom: 0;
      line-height: 1.2;
      font-size: 18px;
      text-transform: uppercase;
      @media #{$mobile} {
        font-size: 12px;
        line-height: 1;
      }
    }
    a{
      display: inline-block;
      font-size: 12px;
      text-transform: uppercase;
      text-decoration: underline;
      color: #ffffff;
      @media #{$mobile} {
        font-size: 10px;
      }
    }
  }
}