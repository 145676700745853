/*=============masonry gallery css start================*/
.masonary-gallery{
  padding: 0;
  .masonary{
    .grid_item{
      figure{
        position: relative;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        transition: all .8s ease;
      }
      .gallery-img{
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        border-radius: 12px;
        transition: all .8s ease;
      }
      figcaption{
        position: absolute;
        bottom: 20px;
        left: 23px;
        color: #ffffff;
        text-decoration: underline;
        font-weight: 600;
        line-height: 1.2;
        z-index: -1;
        text-transform: uppercase;
        transition: all .8s ease;
        @media #{$mobile} {
          bottom: 15px;
          left: 14px;
          font-size: 14px;
        }
      }
      .venobox{
        color: #ffffff;
      }
      figure:hover .gallery-img{
        z-index: -1;
      }
      figure:hover figcaption{
        z-index: 1;
      }
    }
  }
}
