/*============ team css start =============*/
.team{
  padding: 100px 0;
  .team-title{
    text-align: center;
    font-size: 42px;
    margin: 0 0 50px;
    line-height: 1.1;
  }
  .media-left{
    max-width: 110px;
  }
  .media-body{
    padding-left: 25px;
    .media-heading{
      font-size: 19px;
      font-weight: 600;
      margin-top: 10px;
      small{
        display: block;
        text-transform: uppercase;
        margin-top: 10px;
        font-size: 14px;
        color: #7d7d7d;
      }
    }
    p{
      margin-top: 20px;
      color: #666666;
      margin-bottom: 0;
    }
  }
}