/*============= CATERING page css start ===============*/
.catering{
  color: #ffffff;
  background: #000000;
  .nav-bg{
    border: none;
  }
  .bg-dark{
    background: #000000;
  }
  #swipeNav{
    background: #303135;
    a{
      color: #ffffff;
      &:hover{
        background: transparent;
      }
    }
  }
  .menu-cross-btn{
    color: #ffffff;
    &:hover{
      color: red;
    }
  }
  .heading_burger_nav_two{
    .nav-head-two span a{
      color: #ffffff;
    }
  }
  .bg-primary{
    background: #2B333F;
  }
  .subscribe-form-two .form-control{
    @media #{$mobile} {
      margin-bottom: 10px;
    }
  }
  .social-nav-element-two{
    margin-top: 30px;
  }
  .with_image .with_image_text .lead_title{
    color: #ffffff;
  }
  .feature-two .feature-two-feature h5{
    color: #ffffff;
  }
  .feature-two .feature-two-img{
    padding: 10px 0 20px;
    @media #{$laptop} {
      padding: 20% 0 5%;
    }
    @media #{$tab} {
      padding: 0% 0 5%;
    }
  }
  .mix-btn{
    background: $catering-primary-color;
    color: $primaryTextColor;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $catering-primary-color;
      border: 1px solid $catering-primary-color;
    }
  }
  h2, .feature-two .feature-two-text {
    color: #ffffff;
  }
  .feature-two .feature-two-text .feature-two-feature i{
    color: $catering-primary-color;
  }
  .call_to_action{
    background: url("../img/catering-call-to-action-bg.png") center center no-repeat;
    background-size: cover;
  }
  .footer-two .footer-top{
    background: #171d32;
  }
  .footer-two .footer-mid {
    padding: 20px 0 80px;
  }
  .footer-two .footer-bottom{
    background: #141727;
  }
  .footer-two .footer-mid .footer-two-form .form-control{
    background: transparent;
    border: 1px solid rgba(170, 170, 170, 0.26);
  }
  .footer-two .footer-mid .footer-two-form .form-group label {
    color: #d8d8d8;
  }
}

.catering-color{
  color: $catering-primary-color;
}

.catering_light_bg{
  background: #171d32;
}

.catering_dark_bg{
  background: #141727;
}

.catering_header{
  background: url(../img/catering-banner.png) center center no-repeat;
  background-size: cover;
  padding-top: 0;
}

.catering_banner{
  text-align: center;
  margin-top: 0;
  padding: 130px 0 100px;
  @media #{$mobile} {
    padding: 90px 0 30px;
  }
  .catering_banner_inner{
    max-width: 870px;
    margin: auto;
  }
  h1 {
    color:#fff;
  }
  h3{
    font-size: 46px;
    color: #ffffff;
    font-weight: 300;
    margin: 20px 0;
    @media #{$mobile} {
      font-size: 25px;
      margin: 10px 0;
    }
  }
  p{
    font-size: 12px;
    font-weight: 700;
  }
  img{
    @media #{$mobile} {
      max-height: 60px;
    }
  }
  .mix-btn{
    margin: 40px 0 20px;
    color: #ffffff;
    @media #{$mobile} {
      margin: 10px 0;
    }
    &:hover{
      background: transparent;
      color: #ffffff;
    }
  }
}