/*============= portfolio page css start ==============*/
.portfolio{
  .nav-bg{
    border: none;
  }
  .bg-dark{
    background: #ffffff;
    border-bottom: 1px solid #dddddd;
  }
  .bg-primary{
    background: #ffffff;
  }
  .nav-head-two span{
    padding-left: 0;
  }
  .burger-icon{
    padding: 5px 0 0 15px;
  }
  .mix-btn{
    background: $profile-primary-color;
    color: #ffffff;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $profile-primary-color;
      border: 1px solid $profile-primary-color;
    }
  }
  .about_one{
    padding: 100px 0;
  }
  .venobox-video img{
    border-radius: 5px;
  }
  .venobox-video .venobox{

  }
  .venobox-video .venobox:hover{
    background: rgba(51, 51, 51, 0.53);
  }
  .call_to_action_two .call_to_action_two_inner{
    border-radius: 10px;
  }
  .footer-two .footer-top{
    background: url("../img/portfolio-footer.png") center center no-repeat;
    background-size: cover;
    border-radius: 10px;
  }
  .bg-primary {
    border-bottom: 1px solid #ececec;
  }
}

.portfolio_header{
  background: none;
}

.portfolio_banner{
  text-align: center;
  figure{
    position: relative;
  }
  figcaption{
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 95px);
    @media #{$mobile} {
      top: 40px;
      img{
        max-width: 100px;
      }
    }
    p{
      font-size: 22px;
      font-weight: 400;
      margin-top: 15px;
      margin-bottom: 0;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000000;
      @media #{$mobile} {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }

}