/*--------- display property css start ----------*/
.block{
  display: block;
}
.inline-block{
  display: inline-block;
}
.inline{
  display: inline;
}
.flex{
  display: flex;
}
.display-none{
  display: none;
}