/*============== hamburger menu css start ================*/
.heading_burger_nav{
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   z-index: 1500;
   background: #ffffff;
   border-bottom:1px solid #dddddd;
 }
.burger_nav{
  position: relative;
  left: 0;
  right: 0;
  top: 0;
}
.nav-head{
  width:100%;
  background:#ffffff;
  height: 60px;
  line-height:60px;
  //border-bottom:1px solid #dddddd;
  display:inline-block;
  font-size:12px;
  @media #{$desktopLgToMobile} {
    height: 60px;
    line-height: 60px;
  }
  span{
    padding-left:20px;
    font-size:50px;
    font-weight: 700;
    line-height: 55px;
    @media #{$desktopLgToMobile} {
      line-height: 55px;
    }
    a{
      color: $primaryTextColor;
    }
  }
}
.hamburger{
  background:none;
  position:absolute;
  top:0;
  right:0;
  bottom: 0;
  line-height:45px;
  padding:5px 15px 0 15px;
  color:#999;
  border:0;
  font-size:1.4em;
  font-weight:bold;
  cursor:pointer;
  outline:none;
  z-index:10000000000000;
}
.cross{
  background:none;
  position:absolute;
  top:0;
  right:0;
  bottom: 0;
  padding:5px 18px 0 15px;
  color:#999;
  border:0;
  font-size:3em;
  line-height:55px;
  font-weight:bold;
  cursor:pointer;
  outline:none;
  z-index:10000000000000;
}
.menu{
  z-index:1000000;
  font-weight:bold;
  font-size:0.8em;
  width:100%;
  background:#f1f1f1;
  position:absolute;
  text-align:center;
  font-size:12px;
}
.menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}
.menu li {
  display: block;
  padding:15px 0 15px 0;
  border-bottom:#dddddd 1px solid;
}
.menu li:hover{
  display: block;
  background:#ffffff;
  padding:15px 0 15px 0;
  border-bottom:#dddddd 1px solid;
}
.menu ul li a {
  text-decoration:none;
  margin: 0;
  color:#666;
  display: block;
}
.menu ul li a:hover {
  color: #666;
  text-decoration:none;
}
.menu a{
  text-decoration:none;
  color: #666;
}
.menu a:hover{
  text-decoration:none;
  color:#666;
}