/*==============counter up css start=============*/
.counter-up-two{
  background: url("../img/counter-up.png") center center no-repeat;
  background-size: cover;
  padding: 73px 0;
  .counter-up-text{
    font-size: 44px;
    font-weight: 500;
    margin: 0;
    @media #{$mobile} {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .counter-number{
    margin-bottom: 0;
    text-align: center;
    padding: 22px 0 18px;
    line-height: 1.4;
    .counter-number-in{
      font-size: 35px;
      display: inline-block;
    }
    .counter{
      font-size: 35px;
    }
    small{
      font-weight: 600;
      display: block;
      font-size: 14px;
    }
  }

}