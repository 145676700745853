/* ============== color property css start  ================ */
.bg-transparent{
  background: transparent;
  transition: all .4s ease;
}
.bg-white{
  background: #ffffff;
  transition: all .4s ease;
}
.bg-dark{
  background: #2B333F;
  transition: all .4s ease;
}
.bg-primary{
  background: $primaryColor;
  transition: all .4s ease;
}
.bg-light{
  background: #f1f1f1;
}