/*get started section css start*/
.get-started{
  text-align: center;
  background: $primaryColor;
  padding: 55px 0;
  transition: all .3s ease-in;
  //opacity: .9;
  &:hover{
    //opacity: 1;
  }
  @media #{$laptop} {
    padding: 20px 0;
  }
  p {
    color: #3d3f48;
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    font-style: italic;
  }
  .get-started-text{
    display: inline-block;
    color: #ffffff;
    line-height: 40px;
  }
  .phone-number{
    color: $primaryColor;
    display: inline-block;
  }
  .mix-btn{
    margin: 0 0 0 35px;
    border-radius: 20px;
    padding: 9px 28px;
    border: 1px solid #ffffff;
    @media #{$mobile} {
      //margin-top: 10px;
    }
  }
}