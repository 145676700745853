/*============== pricing css start from here ================*/
.pricing{
  padding: 100px 0;
  .pricing-col{
    max-width: 438px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    overflow: hidden;
    transition: box-shadow .3s ease;
    &:hover{
      box-shadow: 0 28px 57px 0 rgba(0, 0, 0, 0.3);
    }
  }
  .price{
    margin: 0;
    font-size: 44px;
    text-align: center;
    background: url("../img/footer-two-bg.jpg") center no-repeat;
    background-size: cover;
    line-height: 1.5;
    color: #ffffff;
    padding: 32px 0;
  }
  .pricing-col-text{
    padding: 0 40px;
    .price-category{
      text-align: center;
      text-transform: uppercase;
      color: #666666;
      border-bottom: 1px solid #e5e6ea;
      margin: 0;
      padding: 14px 0;
      font-weight: 600;
    }
    h4{
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      margin-top: 30px;
      margin-bottom: 5px;
    }
    .price-text{
      color: #666666;
      margin: 0;
    }
    .mix-btn{
      margin: 30px 0 50px;
      width: 100%;
      @media #{$mobile-375} {
        font-size: 11px;
      }
    }
  }
}
.shadow-box{

}