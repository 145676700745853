/*========== email subscribe element two css start =============*/
.subscribe-form-two{
  .form-control{
    height: 40px;
    border-radius: 20px;
    padding: 0 20px;
    margin-bottom: 20px;
    border-color: transparent;
  }
  button{
    background: $catering-primary-color;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
}