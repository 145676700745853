/* ============== Universal variables ===============*/
$primaryFont: 'Open Sans', sans-serif;
$secondaryFont: 'Open Sans', sans-serif;
$primaryColor:  #1fcaea;
$secondaryColor: #8a84d7;
$primaryTextColor:  #252525;
$secondaryTextColor: #808080;
$thirdTextColor: #666666;
$section-heading-font-size: 31px;
$section-sub-heading-font-size: 21px;

/*==============media query variable ============*/
$desktop: '(min-width: 1200px)';
$desktopTab: '(min-width: 768px)';
$desktopLgToMobile: '(max-width: 1399px)';
$laptop: '(max-width: 1199px)';
$tab: 'only screen and (max-width: 991px)';
$mobile: 'only screen and (max-width: 767px)';
$mobile-sm: 'only screen and (max-width: 424px)';
$mobile-375: 'only screen and (max-width: 375px)';
$mobileLandScape: 'only screen and (orientation: landscape) and (max-width: 767px)';

/*================ Resume page variable ================*/
$resume-primary-color: #827364;

/*================ Charity page variable ================*/
$charity-primary-color: #fff719;

/*================ software page variable =================*/
$software-primary-color: #CE3795;

/*================ start up page variable =================*/
$startup-primary-color: #00aeef;

/*================ profile up page variable =================*/
$profile-primary-color: #252525;

/*================ fashion up page variable =================*/
$fashion-primary-color: #dcbe0c;

/*================ consulting up page variable =================*/
$consulting-primary-color: #eac11f;

/*================ catering up page variable =================*/
$catering-primary-color: #d6da31;

/*================ youtuber up page variable =================*/
$youtuber-primary-color: #f85b0e;