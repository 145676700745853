/*=================section with image css start==============*/
.with_image{
  padding: 100px 0;
  .sec_with_img{}
  .with_image_text{
    margin-top: 75px;
    .lead_title{
      font-size: 41px;
      color: #000000;
      margin-top: 5px;
      margin-bottom: 25px;
      @media #{$laptop} {
        margin-top: 0;
        margin-bottom: 10px;
      }
      @media #{$tab} {
        margin-top: 50px;
      }
    }
    .lead-text{
      font-size: 19px;
    }
  }
}
.adoption_story{
  padding: 80px 0 100px;
  .story_title{
    text-align: center;
    font-size: 41px;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 30px;
  }
}