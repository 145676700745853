/*==============social nav css start from here=============*/
.social-nav{
  //display: block;
  margin: 20px 0;
  a{
    margin-right: 5px;
    display: inline-block;
    &:last-child{
      margin-right: 0;
    }
    i{
      color: #000000;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid $resume-primary-color;
      background: #ffffff;
      line-height: 37px;
      text-align: center;
      transition: all .4s ease;
    }
    i:hover{
      color: #ffffff;
      background: $resume-primary-color;
    }
  }
}