/*============== charity template css start here ==============*/
.charity{
  background: #272219;
  color: #ffffff;
  .nav-bg .navigation-right-btn li a:hover{
    background: $charity-primary-color;
    color: $primaryTextColor;
  }
  .nav-bg {
      border-bottom: 1px solid rgba(255, 255, 255, 0.33);
  }
  .bg-dark{
    background: #000000;
  }
  .bg-primary{
    background: #2B333F;
  }
  .mix-btn{
    background: $charity-primary-color;
    color: $primaryTextColor;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $charity-primary-color;
      border: 1px solid $charity-primary-color;
    }
  }
  .with_image{
    .with_image_text{
      margin-top: 115px;
      .lead_title{
        color: #ffffff;
      }
    }
  }
  .footer-two{
    .footer-top{
      background: transparent;
      .footer-two-title{
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      }
    }
    .footer-mid {
      padding-top: 20px;
    }
    .footer-mid .footer-two-form .form-group label{
      color: #ffffff;
    }
    .footer-mid .footer-two-form .form-control {
      background: transparent;
      border: 1px solid #655f5c;
    }

    .footer-bottom{
      background: #221e16;
      color: #ffffff;
      .footer-logo {
        color: #ffffff;
      }
    }
    .f-soc-nav a {
      &:hover i {
        color: #AEAFAF;
      }
      i {
        color: #ffff;
      }
    }
  }
}

.charity_header{
  background: url(../img/charity-bg.jpg) center center no-repeat;
  background-size: cover;
}

.charity_banner{
  text-align: center;
  margin-top: 350px;
  padding: 45px 0;
  background: rgba(37, 37, 37, 0.45);
  @media #{$mobile} {
    margin-top: 150px;
  }
  p{
    margin-bottom: 0;
  }
  h1{
    font-size: 38px;
    line-height: 2;
    margin: 0;
    color: $charity-primary-color;
  }
  .mix-btn{
    background: $charity-primary-color;
    border: 1px solid $charity-primary-color;
    margin: 0 0 10px 20px;
    @media #{$mobile} {
      margin: 10px 0;
    }
    &:hover{
      background: transparent;
      border: 1px solid $charity-primary-color;
      color: $charity-primary-color;
    }
  }
}

.charity_dark_bg{
  background: #221e16;
}