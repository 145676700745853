/*=============grid text block css================*/
.grid_text_one{
  padding: 40px 0;
}
.text-col{

  h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
  }
  p{
    font-size: 14px;
    color: $thirdTextColor;
  }
}