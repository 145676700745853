/*send mail button section css start*/
.send-mail{
  text-align: center;
  background: #f5f5f5;
  padding: 45px 0;
  transition: all .4s ease-in;
  @media #{$laptop} {
    padding: 20px 0;
  }
  p{
    color: #3d3f48;
    font-weight: 600;
    margin: 0;
    font-size: 21px;
  }
  .send-mail-text{
    display: inline-block;
  }
  .phone-number{
    color: $primaryColor;
    display: inline-block;
  }
  .text-gap {
    margin-left: 15px;
  }
  .mix-btn{
    margin: 0 0 0 20px;
    border-radius: 20px;
    padding: 9px 28px;
    @media #{$tab} {
      margin-top: 10px;
    }
  }
}