/*====================header css start==================*/
header{
  color: #ffffff;
  padding-top: 76px;
  @media #{$tab} {
    min-height: initial;
  }
  @media #{$desktopLgToMobile} {
    padding-top: 76px;
  }
  .heading_nav{
    background: transparent;
  }
}

.animate {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.heading_nav .mentis-nav-search.animate {
  border-top-width: 1px;
  z-index: 50
}

.heading_nav .navbar-nav > li > form > .input-group > input,
.heading_nav .navbar-nav > li > form > .input-group > .input-group-btn > .btn {
  border-radius: 0;
}
.heading_nav .navbar-nav > li > form > .input-group > .input-group-btn > .btn-danger{
  background-color: $primaryColor;
  border-color: $primaryColor;
}
.heading_nav .mentis-nav-search {
  display: none;
}
.heading_nav .mentis-nav-search .form-control {
  background-color: rgb(235, 235, 235);
  border-radius: 0;
  border-width: 0;
  font-size: 24px;
  padding: 30px 0;
}
.heading_nav .mentis-nav-search .form-control:focus {
  border-color: transparent;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.heading_nav .mentis-nav-search .input-group-btn > .btn {
  padding: 14px 16px;
  border-radius: 0;
  background-color: $primaryColor;
  border-color: $primaryColor;
}
.heading_nav .mentis-nav-search .input-group-btn > .btn.active,
.heading_nav .mentis-nav-search .input-group-btn > .btn:hover,
.heading_nav .mentis-nav-search .input-group-btn > .btn:focus {
  padding: 14px 16px 14px 15px;
}
.heading_nav .mentis-nav-search .input-group-btn > .btn-default {
  background-color: rgb(245, 245, 245);
}

@media screen and (min-width: 768px){
  .heading_nav .mentis-nav-search {
    background-color: rgb(235, 235, 235);
    display: block;
    position: absolute;
    //top: 100%;
    width: 100%;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    -webkit-transform-origin: 0 0 0;
    -moz-transform-origin: 0 0 0;
    -o-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    visibility: hidden;
  }
  .heading_nav .mentis-nav-search.open {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
    visibility: visible;
  }
  .heading_nav .mentis-nav-search > .container {
    padding: 0;
  }
}