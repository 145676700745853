/*============= fashion page css start ===============*/
.fashion{
  .nav-bg{
    border: none;
  }
  .bg-dark{
    background: #000000;
  }
  .heading_burger_nav_two{
    //background: transparent;
    .nav-head-two span a{
      color: #ffffff;
    }
  }
  .bg-primary{
    background: #2B333F;
  }
  .mix-btn{
    background: $fashion-primary-color;
    color: #ffffff;
    border: 1px solid transparent;
    &:hover{
      background: transparent;
      color: $fashion-primary-color;
      border: 1px solid $fashion-primary-color;
    }
  }
  .gallery_one{
    background: #f2f2f2;
    padding: 100px 0 70px;
    .gallery-single{
      background: #fcfcfc;
      border-radius: 10px;
      margin-bottom: 30px;
      figcaption{
        h4{
          font-size: 14px;
          font-weight: 700;
          line-height: 1.7;
          margin: 0;
          padding: 15px 0 18px;
          small{
            font-size: 14px;
            font-weight: 600;
            //line-height: 1.1;
          }
        }
      }
    }
  }
  .sorting-menu .dropdown .btn-default{color:$fashion-primary-color}
  .call_to_action{
    background: url("../img/fashion-bg.jpg") center center no-repeat;
    background-size: cover;
    padding: 55px 0 65px;
  }
}
.fashion-color{
  color: $fashion-primary-color;
}
.fashion_header{
  background: url(../img/fashion-banner.jpg) center center no-repeat;
  background-size: cover;
  padding-top: 0;
}
.fashion_banner {
  text-align: center;
  margin-top: 0;
  padding: 180px 0 130px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.39);
  }
  .container {
    position: relative;
    z-index: 11;
  }
  //background: rgba(37, 37, 37, 0.45);
  @media #{$mobile} {
    padding: 120px 0 60px;
  }
  .simply-countdown{
    margin: auto;
    font-family: $primaryFont;
    .simply-section{
      background: transparent;
      font-size: 42px;
      font-weight: 300;
      width: 100px;
      height: 100px;
      margin: 0;
      box-shadow: none;
      position: relative;
      @media #{$mobile} {
        font-size: 18px;
        width: 50px;
        height: 80px;
      }
      &:after{
        content: "";
        width: 7px;
        height: 7px;
        background: #ffffff;
        position: absolute;
        top: 34%;
        right: 0;
        border-radius: 50%;
      }
      &:last-child:after{
        display: none;
      }
      .simply-amount{}
      .simply-word{
        font-size: 19px;
        text-transform: uppercase;
        margin-top: 7px;
        font-weight: 700;
        @media #{$mobile} {
          font-size: 12px;
          margin-top: 0;
        }
      }
    }
  }
  h3{
    font-size: 44px;
    color: #ffffff;
    font-weight: 300;
    margin: 40px 0 0;
    @media #{$mobile} {
      font-size: 19px;
    }
  }
  p{
    font-size: 16px;
    line-height: 1.1;
  }
  .mix-btn{
    margin: 40px 0 20px;
    color: #ffffff;
    @media #{$mobile} {
      margin: 10px 0;
    }
    &:hover{
      background: transparent;
      color: #ffffff;
    }
  }
}